import AppLayout from '@admin/common/components/AppLayout';
import DashboardGraphs from '@admin/features/dashboard/components/DashboardGraphs';
import getConfig from 'next/config';
import Head from 'next/head';
import React, { ReactElement } from 'react';

import type { NextPageWithLayout } from './_app';

const DashboardPage: NextPageWithLayout = () => {
  const { publicRuntimeConfig } = getConfig();
  return (
    <>
      <Head>
        <title>Dashboard</title>
        <meta name="description" content="Dashboard" />
        <link
          rel="icon"
          href={`${publicRuntimeConfig.staticFolder}/favicon.ico`}
        />
      </Head>
      <DashboardGraphs />
    </>
  );
};

DashboardPage.getLayout = function getLayout(page: ReactElement) {
  return <AppLayout>{page}</AppLayout>;
};

export default DashboardPage;
