import { AreaConfig } from '@ant-design/charts';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Area } from '@ant-design/plots';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_STATISTICS, UserStatistics } from '@frontend/data-access';
import { Select, Typography } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useState } from 'react';

const TotalUsers = () => {
  const { t } = useTranslation('admin');
  const [growth, setGrowth] = useState<number>(0);
  const [getUserStatistics, { data, loading }] = useLazyQuery<{
    userStatistics: UserStatistics[];
  }>(GET_USER_STATISTICS);
  const [userStats, setUserStats] = useState<UserStatistics[]>([]);
  const [role, setRole] = useState('INTRAPRENEUR');

  useEffect(() => {
    getUserStatistics({
      variables: {
        filterBy: role,
      },
    });
  }, [role, getUserStatistics]);

  useEffect(() => {
    if (data?.userStatistics) {
      if (data?.userStatistics.length) {
        const tmp = data?.userStatistics;
        const growth =
          ((tmp[tmp.length - 1].count - tmp[tmp.length - 2].count) /
            tmp[tmp.length - 2].count) *
          100;
        setGrowth(isNaN(growth) ? 0 : growth);
      }
      setUserStats(data.userStatistics);
    }
  }, [data]);

  const config: AreaConfig = {
    data: userStats,
    loading,
    height: 250,
    padding: 'auto',
    xField: 'date',
    yField: 'count',
    xAxis: {
      tickCount: userStats.length,
    },
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: '#ddd',
            lineDash: [5],
          },
        },
      },
    },
  };

  const handleChange = (value: string) => {
    switch (value) {
      case 'External':
        setRole('EXTERNAL_USER');
        break;
      case 'Intrapreneurs':
        setRole('INTRAPRENEUR');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="grid grid-cols-2">
        <div className="font-medium">{t('total_users')}</div>
        <Select
          size="small"
          defaultValue="Intrapreneurs"
          options={[
            { label: 'Intrapreneurs', value: 'Intrapreneurs' },
            { label: 'External', value: 'External' },
          ]}
          onChange={handleChange}
        />
      </div>
      <div className="flex items-center gap-3 text-3xl mt-4 mb-1">
        <div>
          {userStats.length ? userStats[userStats.length - 1]?.count : ''}
        </div>
        <div className="flex gap-1 text-sm">
          <Typography
            className={`${
              growth >= 0 ? '!text-[#38D200]' : '!text-[#F53F3F]'
            } flex items-center justify-center gap-1`}
          >
            {growth >= 0 ? (
              <CaretUpOutlined className="text-xs" />
            ) : (
              <CaretDownOutlined />
            )}
            {growth.toFixed(2).replace(/\.?0+$/, '')}%
          </Typography>
          <Typography className="!text-gray-700">from last month</Typography>
        </div>
      </div>
      <Area key="total-users" {...config} />
    </>
  );
};

export default TotalUsers;
