import { useQuery } from '@apollo/client';
import { GET_ME, Me } from '@frontend/data-access';
import { PageTitle } from '@frontend/shared/ui';
import { capFirst } from '@frontend/shared/util';
import { Col, Row, Space, Spin } from 'antd';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import React, { ReactElement } from 'react';

import ApplicationStats from './ApplicationStats';
import ChallengeStats from './ChallengeStats';
import IntraChallengeIdeasStats from './IntraChallengeIdeasStats';
import IntraSolutionIdeasStats from './IntraSolutionIdeasStats';
import PopularTopicsStats from './PopularTopicsStats';
import TotalUsers from './TotalUsers';
import UsersByLocationStats from './UsersByLocationStats';
import UsersByRolesStats from './UsersByRolesStats';

const CardContainer = ({ charts }: { charts: ReactElement[] }) => (
  <Row gutter={[8, 8]}>
    {charts.map((chart, index) => (
      <Col span={24} key={index}>
        <div className="border border-gray-400 rounded-2xl p-6">{chart}</div>
      </Col>
    ))}
  </Row>
);

const DashboardGraphs: React.FC = () => {
  const { t } = useTranslation('admin');
  const { data, loading } = useQuery<{ me: Me }>(GET_ME);

  if (loading) {
    return (
      <div className="text-center p-6">
        <Spin />
      </div>
    );
  }

  return (
    <Space className="w-full" direction="vertical" size="small">
      <PageTitle
        title={t('dashboard')}
        description={`${t('welcome')}${
          data?.me?.fullName ? ', ' + capFirst(data?.me?.fullName) + '!' : ''
        }`}
      />
      {data?.me.role === 'ADMIN' ? (
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <CardContainer
              charts={[
                <TotalUsers key={0} />,
                <UsersByRolesStats key={1} />,
                <UsersByLocationStats key={2} />,
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <CardContainer
              charts={[
                <ChallengeStats key={0} />,
                <ApplicationStats key={1} />,
                <IntraChallengeIdeasStats key={2} />,
                <IntraSolutionIdeasStats key={3} />,
                <PopularTopicsStats key={4} />,
              ]}
            />
          </Col>
        </Row>
      ) : (
        <div>
          Please go to{' '}
          <Link href="/intrapreneurship/challenge" className="text-primary-600">
            Intraprenuership challenge
          </Link>{' '}
          to create and manage new challenges.
        </div>
      )}
    </Space>
  );
};
export default DashboardGraphs;
