import { WordCloudConfig } from '@ant-design/charts';
import { WordCloud } from '@ant-design/plots';
import { useQuery } from '@apollo/client';
import { GET_POPULAR_TOPICS, PopularTopic } from '@frontend/data-access';
import React from 'react';

const PopularTopicsStats = () => {
  const { data, loading } = useQuery<{ popularTopics: PopularTopic[] }>(
    GET_POPULAR_TOPICS,
    {
      variables: {
        limit: 50,
      },
    }
  );

  const config: WordCloudConfig = {
    data: data?.popularTopics || [],
    wordField: 'topic',
    weightField: 'count',
    color: '#525355',
    height: 192,
    autoFit: true,
    wordStyle: {
      fontFamily: 'Verdana',
      fontSize: [8, 28],
      rotation: [0, 0],
    },
    state: {
      active: {
        style: {
          lineWidth: 3,
        },
      },
    },
  };

  const chartStyle = {
    width: '100%',
  };

  return (
    <div className="w-full">
      <div className="font-medium">Popular topics</div>
      <WordCloud style={chartStyle} loading={loading} {...config} />
    </div>
  );
};

export default PopularTopicsStats;
