import { useQuery } from '@apollo/client';
import { GET_IDEA_STATISTICS, IdeaStatistics } from '@frontend/data-access';
import React from 'react';

import AreaLine from './AreaLine';

const IntraChallengeIdeasStats = () => {
  const { data, loading } = useQuery<{ ideaStatistics: IdeaStatistics[] }>(
    GET_IDEA_STATISTICS,
    {
      variables: {
        filterBy: 'CHALLENGE',
      },
    }
  );

  return (
    <AreaLine
      title="Intra-challenge proposal"
      data={data?.ideaStatistics || []}
      loading={loading}
    />
  );
};

export default IntraChallengeIdeasStats;
