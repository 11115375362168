import { AreaConfig } from '@ant-design/charts';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Area } from '@ant-design/plots';
import { IdeaStatistics } from '@frontend/data-access';
import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

interface AreaLineProps {
  title: string;
  data: IdeaStatistics[];
  loading: boolean;
}

const AreaLine = ({ title, data, loading }: AreaLineProps) => {
  const [ideasStats, setIdeasStats] = useState<IdeaStatistics[]>([]);
  const [growth, setGrowth] = useState<number>(0);

  useEffect(() => {
    if (data.length) {
      const growth =
        ((data[data.length - 1].count - data[data.length - 2].count) /
          data[data.length - 2].count) *
        100;

      setIdeasStats(data);
      setGrowth(isNaN(growth) ? 0 : growth);
    }
  }, [data]);

  const config: AreaConfig = {
    data: ideasStats,
    loading,
    xField: 'date',
    yField: 'count',
    height: 74,
    smooth: true,
    xAxis: false,
    yAxis: false,
    padding: 4,
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
  };

  const chartStyle = {
    width: '100%',
  };

  return (
    <Row>
      <Col span={10}>
        <div className="font-medium">{title}</div>
        <div className="text-3xl mt-4 mb-1">
          {ideasStats.length ? ideasStats[ideasStats.length - 1]?.count : ''}
        </div>
        <div className="flex gap-1">
          <Typography
            className={`${
              growth >= 0 ? '!text-[#38D200]' : '!text-[#F53F3F]'
            } flex items-center justify-center gap-1`}
          >
            {growth >= 0 ? (
              <CaretUpOutlined className="text-xs" />
            ) : (
              <CaretDownOutlined />
            )}
            {growth.toFixed(2).replace(/\.?0+$/, '')}%
          </Typography>
          <Typography className="!text-gray-700">from last month</Typography>
        </div>
      </Col>
      <Col span={14}>
        <div className="flex items-center h-full">
          <Area key={title} style={chartStyle} {...config} />
        </div>
      </Col>
    </Row>
  );
};

export default AreaLine;
