import { useLazyQuery } from '@apollo/client';
import {
  GET_USER_COUNTRY_STATISTICS,
  UserCountryStatistics,
} from '@frontend/data-access';
import { Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import WorldMap from './WorldMap';

const UsersByLocationStats = () => {
  const [getUserStatistics, { data, loading }] = useLazyQuery<{
    userCountryStatistics: UserCountryStatistics[];
  }>(GET_USER_COUNTRY_STATISTICS);
  const [usersLocationStats, setUsersLocationStats] = useState<
    UserCountryStatistics[]
  >([]);
  const [role, setRole] = useState('INTRAPRENEUR');

  useEffect(() => {
    getUserStatistics({
      variables: {
        filterBy: role,
      },
    });
  }, [role, getUserStatistics]);

  useEffect(() => {
    if (data?.userCountryStatistics) {
      const withCountry = data?.userCountryStatistics.filter(
        (stat) => stat.country
      );
      setUsersLocationStats(withCountry);
    }
  }, [data]);

  const handleChange = (value: string) => {
    switch (value) {
      case 'External':
        setRole('EXTERNAL_USER');
        break;
      case 'Intrapreneurs':
        setRole('INTRAPRENEUR');
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="grid grid-cols-2">
        <div className="font-medium">Users by location</div>
        <Select
          size="small"
          defaultValue="Intrapreneurs"
          options={[
            { label: 'Intrapreneurs', value: 'Intrapreneurs' },
            { label: 'External', value: 'External' },
          ]}
          onChange={handleChange}
        />
      </div>
      {loading ? (
        <div className="flex justify-center mt-4">
          <Spin />
        </div>
      ) : (
        <div className="mt-4">
          <WorldMap stats={usersLocationStats} loading={loading} />
          {usersLocationStats.map((stat, index) => {
            return (
              <div className="flex justify-between mt-1" key={index}>
                <div>{stat.country}</div>
                <div>{stat.count}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UsersByLocationStats;
