import { PieConfig } from '@ant-design/charts';
import { Pie } from '@ant-design/plots';
import { useQuery } from '@apollo/client';
import {
  GET_USER_ROLE_STATISTICS,
  UserRoleStatistics,
} from '@frontend/data-access';
import { capFirst, getLinkLabel } from '@frontend/shared/util';
import React, { useEffect, useState } from 'react';

const UsersByRolesStats = () => {
  const { data, loading } = useQuery<{
    userRoleStatistics: UserRoleStatistics[];
  }>(GET_USER_ROLE_STATISTICS);
  const [userRoleStats, setUserRoleStats] = useState<UserRoleStatistics[]>([]);

  useEffect(() => {
    if (data?.userRoleStatistics) {
      const usersByRole = data?.userRoleStatistics?.map((user) => {
        return {
          ...user,
          role: capFirst(getLinkLabel(user.role)),
        };
      });
      setUserRoleStats(usersByRole);
    }
  }, [data]);

  const config: PieConfig = {
    data: userRoleStats,
    loading,
    height: 285,
    angleField: 'count',
    colorField: 'role',
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    legend: false,
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };

  return (
    <div className="w-full">
      <div className="font-medium mb-4">Users by roles</div>
      <Pie key="users-by-roles" {...config} />
    </div>
  );
};

export default UsersByRolesStats;
