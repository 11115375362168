// import { AreaMap } from '@ant-design/maps';
import { AreaMapConfig } from '@ant-design/charts';
import { UserCountryStatistics } from '@frontend/data-access';
import dynamic from 'next/dynamic';

import data from './world-map.json';

interface WorldMapProps {
  stats: UserCountryStatistics[];
  loading: boolean;
}

const WorldMap = ({ stats, loading }: WorldMapProps) => {
  const AreaMap = dynamic(
    () => import('@ant-design/maps').then((mode) => mode.AreaMap),
    {
      ssr: false,
    }
  );

  const realData = data.features.map((feature) => {
    const realStat = stats.find((stat) => {
      return (
        stat.country?.toLowerCase() ===
          feature.properties.admin?.toLowerCase() ||
        stat.country?.toLowerCase() === feature.properties.name?.toLowerCase()
      );
    });

    return {
      ...feature,
      properties: {
        ...feature.properties,
        count: realStat ? realStat.count : 0,
      },
    };
  });

  data.features = realData;

  const config: AreaMapConfig = {
    loading,
    map: {
      type: 'mapbox',
      style: 'blank',
      zoom: -0.5,
      pitch: 0,
      center: [0, 90],
    },
    source: {
      data: data,
      parser: {
        type: 'geojson',
      },
    },
    color: {
      field: 'count',
      value: [
        'rgb(189,215,231)',
        'rgb(107,174,214)',
        'rgb(49,130,189)',
        'rgb(8,81,156)',
      ],
      scale: {
        type: 'quantile',
      },
    },
    style: {
      opacity: 1,
      // stroke: 'rgb(93,112,146)',
      lineWidth: 0.6,
      lineOpacity: 1,
    },
    state: {
      active: true,
    },
    containerStyle: {
      backgroundColor: '#fff',
      height: 250,
    },
    label: {
      visible: false,
      field: 'name',
      style: {
        fill: '#000',
        opacity: 0.8,
        fontSize: 10,
        stroke: '#fff',
        strokeWidth: 1.5,
        textAllowOverlap: false,
        padding: [5, 5],
      },
    },
    tooltip: {
      items: ['name', 'count', 'pop_est'],
    },
    preserveDrawingBuffer: false,
    zoom: false,
    legend: false,
  };
  return <AreaMap key="area-map" {...config} />;
};

export default WorldMap;
