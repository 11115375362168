import { BarConfig } from '@ant-design/charts';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import { useLazyQuery } from '@apollo/client';
import {
  ApplicationStatistics,
  ChallengeStatistics,
  GET_CHALLENGE_APPLICATION_STATISTICS,
} from '@frontend/data-access';
import { Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

const ApplicationStats = () => {
  const [growth, setGrowth] = useState<number>(0);
  const [type, setType] = useState<string>('INTERNAL');
  const [getApplicationStat, { data, loading }] = useLazyQuery<{
    applicationStatistics: ApplicationStatistics[];
  }>(GET_CHALLENGE_APPLICATION_STATISTICS);
  const [challengeStats, setChallengeStats] = useState<ChallengeStatistics[]>(
    []
  );

  const handleChange = (value) => {
    setType(value);
  };

  useEffect(() => {
    if (data?.applicationStatistics) {
      if (data?.applicationStatistics.length) {
        const tmp = data?.applicationStatistics;
        const growth =
          ((tmp[tmp.length - 1].count - tmp[tmp.length - 2].count) /
            tmp[tmp.length - 2].count) *
          100;
        setGrowth(isNaN(growth) ? 0 : growth);
      }
      setChallengeStats(
        data.applicationStatistics.map((stat) => {
          return {
            ...stat,
            type: `${
              type === 'INTERNAL' ? 'Intrapreneur' : 'Open'
            } challenge applications`,
          };
        })
      );
    }
  }, [data, type]);

  useEffect(() => {
    getApplicationStat({ variables: { filterBy: type } });
  }, [getApplicationStat, type]);

  const config: BarConfig = {
    data: challengeStats,
    loading,
    isGroup: true,
    height: 250,
    xField: 'date',
    yField: 'count',
    seriesField: 'type',
    color: ['#0473EA', '#38D200'],
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: '#ddd',
            lineDash: [5],
          },
        },
      },
    },
    legend: {
      position: 'bottom',
    },
    label: false,
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-2">
        <div className="font-medium">Challenge applications</div>
        <Select
          size="small"
          defaultValue="INTERNAL"
          options={[
            { label: 'Intrapreneur challenge', value: 'INTERNAL' },
            { label: 'Open challenge', value: 'OPEN' },
          ]}
          onChange={handleChange}
        />
      </div>
      <div className="flex items-center gap-3 text-3xl mt-4 mb-1">
        <div>
          {challengeStats.length
            ? challengeStats[challengeStats.length - 1]?.count
            : ''}
        </div>
        <div className="flex gap-1 text-sm">
          <Typography
            className={`${
              growth >= 0 ? '!text-[#38D200]' : '!text-[#F53F3F]'
            } flex items-center justify-center gap-1`}
          >
            {growth >= 0 ? (
              <CaretUpOutlined className="text-xs" />
            ) : (
              <CaretDownOutlined />
            )}
            {growth.toFixed(2).replace(/\.?0+$/, '')}%
          </Typography>
          <Typography className="!text-gray-700">from last month</Typography>
        </div>
      </div>
      <Column key="users-by-roles" {...config} />
    </div>
  );
};

export default ApplicationStats;
